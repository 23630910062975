import styled from 'styled-components';
import banner1 from '../../media/images/banner1.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(rgb(0 0 0 /36%), rgb(0 0 0 /49%)), url(${banner1});
  padding: 80px 10px 160px 10px;

  @media (max-width: 980px) {
    padding: 80px 10px 0px 10px;
  }
`;

export const Content = styled.div`
  width: 50vw;
  margin-left: 50vw;
  padding: 10px;
  color: white;

  @media (max-width: 980px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Title = styled.p`
  font-size: 2.2rem;
  line-height: 1.2;
  color: white;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 1.2rem;
  white-space: pre-wrap;
`;
