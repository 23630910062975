import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import { TokenContext } from '../../models/Customer';

export function PrivateRoute({ children, ...rest }) {
  const { token } = useContext(TokenContext);
  const { i18n } = useTranslation();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${i18n.language}/customers/login`,
              state: { redirectedFrom: location.pathname },
            }}
          />
        )
      }
    />
  );
}

export function PrivateRouteWithLogin({ children }) {
  const { token } = useContext(TokenContext);
  const { i18n } = useTranslation();
  return (
    <Route
      render={() =>
        !token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${i18n.language}/customers/account`,
            }}
          />
        )
      }
    />
  );
}
