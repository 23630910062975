import { createContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const url = `${process.env.REACT_APP_API_DOMAIN}/staticInfo`;

const StaticInfoContext = createContext(null);

export default function useStaticInfo(pageName) {
  const {
    i18n: { language },
  } = useTranslation();

  async function getStaticInfo() {
    try {
      const {
        data: { data },
      } = await axios.get(`${url}`, {
        params: { limit: 999 },
      });

      const cleanData = data.map((e) => ({
        value: e?.value,
        key: e?.key,
        language: e.language ? (e?.language === 'En' ? 'en' : 'zh') : '',
        pageName: e.pageName,
      }));

      return cleanData;
    } catch (error) {
      return [];
    }
  }

  function getStaticValue(key = [], data = []) {
    if (!Array.isArray(data)) {
      return {};
    }

    const object = key.reduce((acc, input) => {
      const target =
        data.find((e) => {
          const languageKey = language === 'en' ? input : `${input}_zh`;

          if (!pageName) {
            return e.key === 'url' && e.pageName === input;
          }

          return e.key === (e.language ? languageKey : input) && pageName === e.pageName;
        }) || {};
      const { value = '', language: lang } = target || {};

      if (!pageName) {
        return { ...acc, [input]: value };
      }

      if (!lang) {
        return { ...acc, [input]: value };
      }

      if (lang && lang === language) {
        return { ...acc, [input]: value };
      }

      return { ...acc, [input]: '' };
    }, {});

    return object;
  }

  return {
    getStaticInfo,
    getStaticValue,
    StaticInfoContext,
    language,
  };
}
