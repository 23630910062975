import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const fetchSystemUrl = `${process.env.REACT_APP_API_DOMAIN}/system`;

export default function useSystem() {
  const [maintenanceData, setMaintenanceData] = useState({});

  async function fetchSystem() {
    try {
      const {
        data: { data },
      } = await axios.get(fetchSystemUrl);
      setMaintenanceData({ ...data, lastUpdated: moment().toISOString() });
    } catch (error) {
      setMaintenanceData({
        maintenance: true,
        maintenanceMessage:
          'Our website is temporarily unavailable due to a system upgrade.<br/><br/>Website service will resume normal later. If you need any assistance, please call 2830-3845.<br/><br/>We strive for providing quality online service at all times. Thank you for your support.',
        maintenanceMessageZh:
          '我們的網站正進行系統升級，暫時無法使用。<br/><br/>網站將於稍後時間恢復正常運作。如需協助，請致電2830-3845。<br/><br/>我們期待為你提供更優質的線上服務，感謝支持。',
        maintenanceDomainBlacklist: process.env.REACT_APP_MAINTENANCE_DOMAIN_BLACK_LIST.split(','),
        lastUpdated: moment().toISOString(),
      });
    }
  }

  return {
    maintenanceData,
    fetchSystem,
  };
}
