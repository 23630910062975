import React, { useEffect, useContext, useState, Suspense } from 'react';
import './App.css';
import './index.css';
import './assets/css/suneditor.min.css';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer/Footer';

import { useCart, CartContext } from './models/Cart';
import { useCustomer, CustomerContext } from './models/User';
import { useAuth, TokenContext } from './models/Customer';

import useStaticInfo from './models/StaticInfo';

import LoadingSprinner from './components/Loading';
import useSystem from './models/System';
import RenderRoutes from './components/RenderRoutes';
import Header from './components/Header/Header';

function LoadingView({ loading }) {
  // const { pathname } = useLocation();

  const { t } = useTranslation();

  // if (!pathname.includes('PaymentLink')) {
  //   return null;
  // }

  return (
    <div style={{ flex: 1 }}>
      <LoadingSprinner loading={loading} msg={t('wait')} />
    </div>
  );
}

function App() {
  const [staticInfo, setStaticInfo] = useState(null);

  const auth = useAuth();
  const customer = useCustomer();
  const generalCart = useCart('general');
  const singleCart = useCart('single');
  const { clearTemporaryCart: singleCartClear } = singleCart;
  const { t, i18n } = useTranslation();
  const { getStaticInfo, StaticInfoContext } = useStaticInfo();
  const { fetchSystem, maintenanceData } = useSystem();

  const defaultTimeZone = 'Asia/Hong_Kong';
  moment.tz.setDefault(defaultTimeZone);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('lang') || 'en');

    fetchSystem();
    getStaticInfo().then((e) => {
      setStaticInfo(e);
    });
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TokenContext.Provider value={auth}>
        <CustomerContext.Provider value={customer}>
          <CartContext.Provider value={{ generalCart, singleCart }}>
            <StaticInfoContext.Provider value={staticInfo}>
              <BrowserRouter forceRefresh={false}>
                <Header />
                <link
                  href="https://fonts.googleapis.com/css?family=Lato&display=swap"
                  rel="stylesheet"
                />
                <Helmet>
                  <title>{t('header.PageTitle')}</title>
                  <meta name="description" content={t('header.PageTitle')} />
                </Helmet>
                {isEmpty(maintenanceData) || !staticInfo ? (
                  <LoadingView loading />
                ) : (
                  <RenderRoutes
                    staticInfo={staticInfo}
                    singleCartClear={singleCartClear}
                    maintenanceData={maintenanceData}
                    fetchSystem={fetchSystem}
                  />
                )}
                <Footer isMaintenance={maintenanceData.maintenance} />
              </BrowserRouter>
            </StaticInfoContext.Provider>
          </CartContext.Provider>
        </CustomerContext.Provider>
      </TokenContext.Provider>
    </Suspense>
  );
}

export default App;
