import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { set } from 'lodash';
import icon from '../../../media/icons/mobile.png';
// import SearchwithFLEX from '../../General/SearchwithFLEX';
import ScreenWidthListener from '../../General/ScreenWidthListener';
import searchicon from '../../../media/icons/search.png';

const Ul = styled.div`
  list-style: none;
  display: flex;
  z-index: 9999;
  margin-top: 40px;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  top: 0;
  height: 100%;
  width: 100%;
  right: ${({ open }) => (open ? 'auto' : '0')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.5s ease-in-out;
  li {
    color: #fff;
  }
`;

const StyledLink = styled(Link)`
  color: #000;
  &:hover {
    text-decoration: none;
    color: #000;
  }
`;

const UpperMenu = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-flex: 1;
  flex: 1;
`;

const UpperMenuItem = styled.div`
  background: #fff;
  padding: 10px;
`;

const Divider = styled.div`
  height: 1px;
  background: #8888;
  width: 100%;
`;

const LowerMenu = styled.div`
  background: #333333;
  display: flex;
  flex-direction: column;
  -webkit-flex: 1;
  flex: 1;
`;

const SearchRow = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  background-color: #333333;
`;

const SearchInput = styled.input`
  padding: 1px 28px 0 12px;
  border: none;
  background: #333333;
  font-size: 1rem;
  width: 100%;
  height: 30px;
  color: #bbbbbb;

  &:hover {
    color: #bbbbbb;
  }
  :focus {
    color: #bbbbbb;
  }
`;

const SearchForm = styled.form`
  display: flex;
  margin: 15px;
`;

const SearchSubmitDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchSubmit = styled.div`
  border: none;
  background: none;
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;

  &:focus {
    color: #fff;
    box-shadow: none;
  }
`;

const SearchImg = styled.img`
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  pointer-events: none;
`;

const Background = styled.div`
  background: #333;
`;

const LowerMenuDivider = styled.div`
  height: 1px;
  background: #000;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 175px;
  background: #000;
  position: absolute;
  margin-left: -20px;
  margin-top: -10px;
`;

const VerticalDividerdiv = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  background: #333333;
`;

const LowerMenuItem = styled.div`
  display: flex;
  -webkit-flex: 1;
  flex: 1;
`;

const LowerMenuRow = styled.div`
  display: flex;
  padding: 10px;
`;

const LowerLink = styled(Link)`
  color: #fff;
  font-size: 13px;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const MobileAppimg = styled.img`
  height: 15px;
  width: 10px;
`;

const MobileApptext = styled.span`
  color: #fff;
  margin-left: 10px;
  font-size: 13px;
`;

const CloseLayer = styled.div`
  height: 1000px;
  width: 100%;
`;
const Slidediv = ({ open, setopen }) => {
  const { t, i18n } = useTranslation();
  const { outerWidth } = ScreenWidthListener();
  const { pathname } = useLocation();
  const history = useHistory();
  const [keyWords, setKeyWords] = useState();

  const changeLanguage = () => {
    const lang = i18n.language === 'zh' ? 'en' : 'zh';
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    setopen(!open);
  };

  useEffect(() => {
    if (open === true && outerWidth < 980) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
    }
  }, [outerWidth, open]);

  function changeUrl() {
    const currentUrl = window.location.href.replace(/^.*\/\/[^\/]+/, '');
    if (currentUrl.includes('/en')) {
      return currentUrl.replace('/en', '/zh');
    }
    if (pathname.includes('/zh')) {
      return currentUrl.replace('/zh', '/en');
    }
    return `/${i18n.language}${currentUrl}`;
  }

  function handelSearch(e) {
    if (!keyWords) return;
    if (e.key === 'Enter' || e.type === 'click') {
      setopen(false);
      history.push({
        pathname: `/${i18n.language}/searches/index?keyword=/${keyWords}`,
      });
    }
  }

  return (
    <Ul open={open}>
      <div>
        <UpperMenu>
          <UpperMenuItem>
            <StyledLink to={`/${i18n.language}/car_parks/map`} onClick={() => setopen(false)}>
              {t('header.find_carpark')}
            </StyledLink>
          </UpperMenuItem>
          <Divider />
          <UpperMenuItem>
            <StyledLink to={`/${i18n.language}/customers/account`} onClick={() => setopen(false)}>
              {t('header.my_account')}
            </StyledLink>
          </UpperMenuItem>
          <Divider />
          <UpperMenuItem>
            <StyledLink to={`/${i18n.language}/customers/login`} onClick={() => setopen(false)}>
              {t('header.login')}
            </StyledLink>
          </UpperMenuItem>
          <Divider />
          <UpperMenuItem>
            <StyledLink to={`/${i18n.language}/cart`} onClick={() => setopen(false)}>
              {t('header.cart')}
            </StyledLink>
          </UpperMenuItem>
        </UpperMenu>

        <LowerMenu>
          <SearchRow>
            <SearchForm method="get" action="">
              <SearchInput
                placeholder={t('header.search')}
                onChange={(e) => setKeyWords(e.target.value)}
                onKeyPress={handelSearch}
              />
              <SearchSubmitDiv>
                <SearchSubmit onClick={handelSearch} />
                <SearchImg src={searchicon} />
              </SearchSubmitDiv>
            </SearchForm>
          </SearchRow>
          <Background>
            <LowerMenuDivider />
            <VerticalDividerdiv>
              <LowerMenuItem>
                <div>
                  <LowerLink to={`/${i18n.language}/news/index`} onClick={() => setopen(false)}>
                    {t('header.news')}
                  </LowerLink>
                </div>
              </LowerMenuItem>

              <VerticalDivider />
              <LowerMenuItem>
                <div>
                  <LowerLink to={`/${i18n.language}/our-company`} onClick={() => setopen(false)}>
                    {t('header.our_company')}
                  </LowerLink>
                </div>
              </LowerMenuItem>
            </VerticalDividerdiv>
            <LowerMenuDivider />
            <LowerMenuRow>
              <LowerMenuItem>
                <div>
                  <LowerLink to={`/${i18n.language}/awards/index`} onClick={() => setopen(false)}>
                    {t('header.awards')}
                  </LowerLink>
                </div>
              </LowerMenuItem>
              <LowerMenuItem>
                <div>
                  <LowerLink
                    to={`/${i18n.language}/customer_enquiries/index`}
                    onClick={() => setopen(false)}
                  >
                    {t('header.customer_service')}
                  </LowerLink>
                </div>
              </LowerMenuItem>
            </LowerMenuRow>
            <LowerMenuDivider />
            <LowerMenuRow>
              <LowerMenuItem>
                <div>
                  <LowerLink
                    to={`/${i18n.language}/business_enquiries/index`}
                    onClick={() => setopen(false)}
                  >
                    {t('header.business_enquiries')}
                  </LowerLink>
                </div>
              </LowerMenuItem>
              <LowerMenuItem>
                <div>
                  <LowerLink to={changeUrl} onClick={changeLanguage}>
                    {t('header.change_lang')}
                  </LowerLink>
                </div>
              </LowerMenuItem>
              <LowerMenuDivider />
            </LowerMenuRow>
            <LowerMenuDivider />
            <LowerMenuRow>
              <LowerMenuItem>
                <StyledLink to={`/${i18n.language}/mobile-app`} onClick={() => setopen(false)}>
                  <MobileAppimg src={icon} alt="" />
                  <MobileApptext>{t('header.MobileApp')}</MobileApptext>
                </StyledLink>
              </LowerMenuItem>
            </LowerMenuRow>
          </Background>
        </LowerMenu>

        <CloseLayer onClick={() => setopen(false)} />
      </div>
    </Ul>
  );
};

export default Slidediv;
