import React from 'react';
import { Container, Content, Text, Title } from './styles';

export default function MaintenancePage({ maintenanceData }) {
  const { maintenanceMessage, maintenanceMessageZh } = maintenanceData || {};

  return (
    <Container>
      <Content>
        <Title>System Upgrade</Title>
        <Text dangerouslySetInnerHTML={{ __html: maintenanceMessage }} />
        <Title>系統升級</Title>
        <Text dangerouslySetInnerHTML={{ __html: maintenanceMessageZh }} />
      </Content>
    </Container>
  );
}
