import React from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UpperHeader, LowerHeader } from './HeaderComponents';

const MainContainer = styled.div`
  display: ${({ hideHeader }) => (hideHeader ? 'none' : 'block')};
`;

function Header() {
  const { i18n } = useTranslation();
  const { pathname, hash = '' } = useLocation();
  const changeLanguage = () => {
    const lang = i18n.language === 'zh' ? 'en' : 'zh';
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  const history = useHistory();

  const replacedHash = hash.replace('#', '');

  const hideHeader =
    history?.location?.pathname === '/customers/validate_credit_card' ||
    history?.location?.pathname.includes('customers/DeleteAccount/') ||
    history?.location?.pathname.includes('customers/DeleteAccountTnc') ||
    history?.location?.pathname.includes('customers/DeleteAccountSurvey') ||
    (history?.location?.pathname.includes('our-company') && replacedHash === 'mobile');

  return (
    <MainContainer className="Header" hideHeader={hideHeader}>
      <UpperHeader changeLanguage={changeLanguage} pathname={pathname} />
      <LowerHeader />
    </MainContainer>
  );
}

export default withRouter(Header);
